<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 유해위험기계기구 기본정보 -->
      <c-card title="LBL0002548" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="editable && equipInfo.machineryClassificationName" class="col-3">
            <c-label-text title="LBL0002500" :value="equipInfo.machineryClassificationName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.machineryName" class="col-3">
            <c-label-text title="LBL0002507" :value="equipInfo.machineryName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.emplacementPlace" class="col-3">
            <c-label-text title="제조번호" :value="equipInfo.emplacementPlace"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.ewrNo" class="col-3">
            <c-label-text title="형식번호" :value="equipInfo.ewrNo"></c-label-text>
          </div>
        </template>
      </c-card>
      <!-- 유해위험기계기구 검사이력 -->
      <c-table
        ref="table"
        title="LBL0002549"
        tableId="hazardEquipmentHis"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="equipInfo.hazardEquipmentHistoryModels"
        :usePaging="false"
        :filtering="false"
        :checkClickFlag="false"
        :merge="grid.merge"
        selection="multiple"
        rowKey="inspectionNo"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <!-- 삭제 -->
            <c-btn v-if="editable && equipInfo.hazardEquipmentHistoryModels.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeItem" />
            <!-- 저장 -->
            <c-btn
              v-if="editable && equipInfo.hazardEquipmentHistoryModels.length > 0"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="equipInfo.hazardEquipmentHistoryModels"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveHistory"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="suffixTitle">
          <font color="#C10015">
            <!-- ※ 각 행에 해당하는 안전검사결과의 버튼을 '클릭'시 안전검사 실시가 가능합니다.  -->
            {{$message('MSG0000753')}}
          </font>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'click'">
            <q-chip
              v-if="props.row.editFlag !== 'C'"
              outline square
              color="blue"
              :clickable="true"
              :editable="editable"
              text-color="white"
              @click="linkClick(props.row, col.name)">
              <!-- 클릭 -->
              {{$label('LBL0002550')}}
            </q-chip>
            <q-chip
              v-else
              color="red"
              square
              text-color="white">
              <q-avatar icon="info" color="red" text-color="white" />
              <!-- 저장하세요 -->
              {{$label('LBL0002551')}}
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>

import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-equipment-history',

  props: {
    equipInfo: {
      type: Object,
      default: () => ({
        hhmHazardousMachineryId: '',
        equipmentCd: '',
        plantName: '',
        machineryClassificationName: '',
        itemNo: '',
        machineryName: '',
        emplacementMonth: '',
        inspectionSubjectFlagName: '',
        inspectionCycleName: '',
        gradeName: '',
        resultName: '',
        departmentDeptName: '',
        departmentUserName: '',
        inspectionKindName: '',
        machineryClassification: null,
        hazardEquipmentHistoryModels: []
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            required: true,
            name: "inspectionDate",
            field: "inspectionDate",
            // 검사일
            label: "LBL0002552",
            style: 'width:120px',
            align: "center",
            type: 'date',
            sortable: false,
          },
          {
            name: "departmentDeptName",
            field: "departmentDeptName",
            // 주관부서
            label: "LBL0002524",
            style: 'width:120px',
            align: "center",
            type: 'dept',
            deptCd: 'departmentDeptCd',
            sortable: false,
          },
          {
            name: "inspectionAgency",
            field: "inspectionAgency",
            // 기관(검사원명)
            label: "LBL0002553",
            style: 'width:120px',
            align: "left",
            type: 'text',
            sortable: false,
          },
          {
            name: "inspectionResultDetail",
            field: "inspectionResultDetail",
            // 검사결과 상세
            label: "LBL0002554",
            style: 'width:350px',
            type: 'textarea',
            align: "left",
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            // 안전검사결과
            label: 'LBL0002555',
            align: 'center',
            type: 'custom',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            // 첨부파일
            label: 'LBLATTACHMENT',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'HAZARD_EQUIPMENT',
            keyText: 'inspectionNo',
            sortable: false,
          },
        ],
        height: '700px',
        data: [],
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      comboItems: [
        { code: 'HRC000005', codeName: '합격' },
        { code: 'HRC000010', codeName: '불합격' },
      ],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.hhm.hazard.hazardHistory.insert.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.hazardHistory.delete.url;
      this.getStdUrl = selectConfig.sop.hhm.hazard.check.get.url;
      this.getUrl = selectConfig.sop.hhm.hazard.hazardHistory.get.url;
    },
    addItem() {
      this.equipInfo.hazardEquipmentHistoryModels.splice(0, 0, {
        hhmHazardousMachineryId: this.equipInfo.hhmHazardousMachineryId,
        inspectionNo: uid(),
        inspectionDate: '',
        departmentDeptCd: '',
        inspectionAgency: '',
        inspectionResultDetail: '',
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveHistory() {
      if (this.$comm.validTable(this.grid.columns, this.equipInfo.hazardEquipmentHistoryModels)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } 
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
    },
    linkClick(result) {
      // this.equipInfo.machineryName + '의 안전검사결과 상세'
      this.popupOptions.title = this.$comm.getLangLabel('LBL0002556', {s1: this.equipInfo.machineryName});
      this.popupOptions.target = () => import(`${'./hazardEquipmentHistoryResult.vue'}`);
      this.popupOptions.param = {
        hhmHazardousMachineryId: this.equipInfo.hhmHazardousMachineryId,
        inspectionNo: result ? result.inspectionNo : '', 
        machineryClassification: this.equipInfo.machineryClassification
      } 
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.width = '80%';
      this.popupOptions.closeCallback = this.closeItemPopup;
    },
    closeItemPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('saveCallback', this.equipInfo.hhmHazardousMachineryId)
    },
  }
};
</script>